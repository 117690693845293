@import '../../global-styles/index.scss';

.article {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
  background-color: white;
  align-items: center;
}

.image {
  position: absolute;
  min-height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.wrapper {
  display: flex;
  height: 100%;
  margin-top: 4.5rem;
  background: linear-gradient(172deg, white 75%, var(--clr-1) 75.2%);

  @include media(medium) {
    & {
      flex-direction: column;
      overflow-y: scroll;
      background: none;
    }
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  padding: 9rem 0 9rem 5rem;
  width: 36rem;
  margin-right: auto;
  @include media(medium) {
    & {
      padding: 2rem 2rem 7rem;
      background: linear-gradient(172deg, white 70%, var(--clr-1) 70.2%);
      width: 100%;
    }
  }
}

.title {
  color: var(--clr-5);
  font-family: var(--font-family-1);
  font-size: var(--font-size-6);
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  min-width: 2rem;
  color: var(--clr-5);
  font-size: var(--font-size-4);
  font-family: var(--font-family-4);
  background-color: var(--clr-7);
  border-radius: 9999px;
}

.close {
  align-self: flex-end;
  transform: rotate(45deg);
}

.next {
  font-size: inherit;
  font-weight: 600;
  font-family: var(--font-family-2);
}

.socialLinkContainer {
  display: flex;
  gap: 0.5rem;
}

.socialButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  min-width: 2.5rem;
  color: var(--clr-5);
  font-size: var(--font-size-3);
  font-family: var(--font-family-4);
  background-color: var(--clr-7);
  border-radius: 9999px;
}

.svg {
  width: 1.5rem;
  height: 1.5rem;
  color: black;
}

.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include media(medium) {
    & {
      margin-top: 2rem;
    }
  }
}

.winnerSubtitle {
  color: var(--clr-2);
  font-weight: 600;
  font-size: var(--font-size-0);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.ticket {
  height: 1rem;
}

.achievementList {
  font-size: var(--font-size-0);
  color: var(--clr-5);
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  gap: 0.5rem;
}

.nextNinjaWrapper {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.nextNinjaContainer {
  height: fit-content;
  font-size: var(--font-size-3);
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  align-items: center;
}

.nextNinjaText {
  color: var(--clr-5);
  font-size: var(--font-size-2);
  font-weight: 500;
}
