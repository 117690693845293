.card {
  max-width: 17rem;
  padding: 3rem 1.4rem 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  background-color: var(--clr-6);
  color: var(--clr-5);
  font-weight: 600;
}

.deal {
  background-color: white;
  transform: translateY(-10%);
}

.dealText {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--clr-7);
}

.heading {
  font-family: var(--font-family-1);
  text-transform: uppercase;
  font-size: var(--font-size-6);
  line-height: 1.1;
}

.info {
  margin: 2rem 0 1.5rem;
  font-weight: 600;
  font-size: var(--font-size-1);
  line-height: 1.3;
}

.priceText {
  font-size: var(--font-size-0);
  line-height: 1;
}

.priceAmount {
  font-size: var(--font-size-2);
}

.cta {
  margin: 0.5rem 0 0 auto;
  padding: 0.4rem 1.5rem;
  border-radius: 22px;
  background-color: var(--clr-7);
  color: white;
  text-transform: uppercase;
  font-size: var(--font-size-0);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
