// Fonts are imported in public/index.html

:root {
  // Fonts
  font-size: 100%;
  --font-scale-ratio: 1.2;

  --font-size-0: 1rem;
  --font-size-1: calc(var(--font-size-0) * var(--font-scale-ratio));
  --font-size-2: calc(var(--font-size-1) * var(--font-scale-ratio));
  --font-size-3: calc(var(--font-size-2) * var(--font-scale-ratio));
  --font-size-4: calc(var(--font-size-3) * var(--font-scale-ratio));
  --font-size-5: calc(var(--font-size-4) * var(--font-scale-ratio));
  --font-size-6: calc(var(--font-size-5) * var(--font-scale-ratio));
  --font-size-7: calc(var(--font-size-6) * var(--font-scale-ratio));
  --font-size-8: calc(var(--font-size-7) * var(--font-scale-ratio));
  --font-size-9: calc(var(--font-size-8) * var(--font-scale-ratio));
  --font-size-10: calc(var(--font-size-9) * var(--font-scale-ratio));

  --font-family-1: 'Yanone Kaffeesatz', sans-serif; // no additional font weights
  --font-family-2: 'Poppins', sans-serif; // font-weights: 200, 300, 600, 800
  --font-family-3: 'Sanchez', serif; // font-weights: 700
  --font-family-4: 'Montserrat', sans-serif; // font-weights: 600

  // Colors
  --clr-1: hsl(71, 88%, 63%);
  --clr-2: hsl(226, 57%, 51%);
  --clr-3: hsla(0, 0%, 100%, 0.7);
  --clr-4: hsl(226, 57%, 51%);
  --clr-5: hsl(226, 61%, 18%);
  --clr-6: hsl(207, 35%, 80%);
  --clr-7: hsl(15, 95%, 61%);
  --clr-8: hsl(0, 3%, 94%);

  // Spacing
  --primary-page-gap: 5rem;
  --max-width: 62rem;
}

body {
  font-family: var(--font-family-2);
}
