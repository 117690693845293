@import '../../global-styles/index.scss';

.section {
  padding: var(--primary-page-gap) 0 10rem;
  background: linear-gradient(172deg, transparent 80%, var(--clr-1) 80.2%);
  @include media(medium) {
    & {
      padding: var(--primary-page-gap) 2rem;
      background: linear-gradient(
        172deg,
        transparent 95%,
        var(--clr-1) 95.2%
      );
    }
  }
}

.wrapper {
  margin: auto;
  max-width: 80rem;
}

.box {
  width: 100%;
}

.box > *:last-child {
  border-bottom: 3px solid var(--clr-2);
}

.title {
  font-family: var(--font-family-1);
  font-size: var(--font-size-6);
  color: var(--clr-5);
  text-align: center;
  padding: 1rem;
  border: 3px solid var(--clr-2);
  border-bottom: 0;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, min-content) auto;
  grid-template-rows: repeat(2, min-content);
  grid-auto-flow: row;
  border-right: 3px solid var(--clr-2);
  @include media(medium) {
    & {
      display: flex;
      flex-direction: column;
      border-bottom: 3px solid var(--clr-2);
    }
  }
}

.grid > * {
  border: 3px solid var(--clr-2);
  border-bottom: 0;
  border-right: 0;
}

.spanRows {
  grid-column: 3;
  grid-row: 1 / 3;
  @include media(medium) {
    & {
      order: 1;
    }
  }
}

.yourNFTs {
}
