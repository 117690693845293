$maskImageColorValue: (
  black 2px,
  black 5%,
  black 10%,
  black 15%,
  black 20%,
  black 25%,
  black 30%,
  black 35%,
  black 40%,
  black 45%
);

.cell {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cell > * {
  background-color: var(--clr-1);
}

.wrapper {
  flex: 1;
  background-color: var(--clr-1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 3rem 0;
}

.limited {
  position: relative;
  background-color: white;
  text-align: center;
  color: var(--clr-2);
  padding: 1.25rem;
  font-size: var(--font-size-2);
  font-weight: 600;
  border-top: 3px solid var(--clr-2);
}

.limited:before {
  position: absolute;
  top: -1.55rem;
  left: 50%;
  width: 0;
  content: '';
  font-size: 0;
  line-height: 0;
  margin-left: -2.15rem;
  border-bottom: 1.55rem solid var(--clr-2);
  border-right: 2.15rem solid transparent;
  border-left: 2.15rem solid transparent;
}

.limited:after {
  position: absolute;
  top: -1.25rem;
  left: 50%;
  width: 0;
  content: '';
  font-size: 0;
  line-height: 0;
  margin-left: -1.75rem;
  border-bottom: 1.25rem solid white;
  border-right: 1.75rem solid transparent;
  border-left: 1.75rem solid transparent;
}

.title {
  color: var(--clr-2);
  font-size: var(--font-size-2);
  font-weight: 600;
  text-align: center;
}

.mediaWrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 14rem;
  width: 100%;
  padding: 0.625rem;
}

.mediaWrapper > * {
  grid-column-start: 1;
  grid-row-start: 1;
  min-width: 0;
  min-height: 0;
}

.video {
  height: 100%;
  justify-self: right;
}

.ticket {
  align-self: flex-end;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  height: 5rem;
  background-color: rgba(white, 0.6);
  border: 0.2rem solid var(--clr-2);
  mask-image: radial-gradient(
      circle at left center,
      transparent 0.75rem,
      $maskImageColorValue,
      black 70%,
      transparent 0
    ),
    radial-gradient(
      circle at right center,
      transparent 0.75rem,
      $maskImageColorValue,
      transparent 0
    );
  transform: rotate(-11deg);
  transform-origin: top left;
}

.ticket:before,
.ticket:after {
  content: '';
  position: absolute;
  width: 0.75rem;
  height: 1.625rem;
  top: calc(50% - 0.8125rem);
  background-color: var(--clr-2);
  border: 0.25rem solid var(--clr-2);
}

.ticket:before {
  left: 0;
  border-radius: 0 9999px 9999px 0;
}
.ticket:after {
  right: 0;
  border-radius: 9999px 0 0 9999px;
}

.playButton {
  height: 80%;
}

.rarityText {
  color: var(--clr-2);
  font-size: var(--font-size-5);
  font-weight: 600;
}

.rarityText > span {
  font-size: var(--font-size-1);
}

.pieChart {
  width: 7.75rem;
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  border-radius: 50%;
}

.pieChart:before,
.pieChart:after {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.pieChart:before,
.pieChart:after {
  inset: 0;
}

.pieChart:before {
  margin: 0.375rem 0.375rem;
  width: 7rem;
  aspect-ratio: 1;
  background: conic-gradient(white 100%, #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(98% - 0.25rem),
    #000 calc(100% - 0.25rem)
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(98% - 0.25rem),
    #000 calc(100% - 0.25rem)
  );
}

.pieChart:after {
  background: conic-gradient(var(--clr-2) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
