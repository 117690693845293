@import '../../global-styles/index.scss';

.footer {
  padding: var(--primary-page-gap) 0 3rem;
  background-color: var(--clr-5);
}

.wrapper {
  margin: 0 auto;
  max-width: 80rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 0.125rem solid white;
  @include media(medium) {
    & {
      flex-direction: column;
      margin: 0 2rem;
    }
  }
}

.linksWrapper {
  padding-left: 1.75rem;
  @include media(medium) {
    & {
      padding-left: 0;
    }
  }
}

.linksWrapper > * {
  padding-bottom: 1rem;
}

.footerLinks {
  color: white;
  font-weight: 600;
  font-size: var(--font-size-0);
}

.socialLinksWrapper {
  padding: 2rem 4rem;
  height: fit-content;
  align-self: flex-end;
  display: flex;
  gap: 1.625rem;
  border: 0.125rem solid white;
  border-bottom: none;
  color: white;
  @include media(medium) {
    & {
      align-self: auto;
    }
  }
}

.icon {
  height: 2.5rem;
  width: 2.5rem;
  color: inherit;
}
