@import '../../global-styles/index.scss';

.section {
  background-image: linear-gradient(
    to bottom,
    var(--clr-1) 70%,
    rgb(0, 0, 0, 0) 70%
  );
}

.wrapper {
  margin-left: 16.67vw;
  display: flex;
  flex-direction: column;
  @include media(medium) {
    & {
      margin: 0 2rem;
      padding-top: 3rem;
    }
  }
}

.maxWidthCenter {
  margin: auto;
  max-width: 80rem;
}

.title {
  font-family: var(--font-family-1);
  font-size: var(--font-size-6);
  color: var(--clr-5);
}

.ninjaCardWrapper {
  display: flex;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scrollbar-width: none;
  gap: 1rem;
  margin-right: 0;
  padding-bottom: 1rem;
}

.ninjaCardWrapper::-webkit-scrollbar {
  display: none;
}

.seeMore {
  margin-right: 16.67vw;
  display: flex;
  justify-content: space-between;
  padding: 3rem;
  font-size: var(--font-size-3);
  font-weight: 600;
  @include media(medium) {
    & {
      margin-right: 0;
      padding: 0;
    }
  }
}

.seeMore > * {
  color: var(--clr-5);
  font-weight: inherit;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  min-width: 2rem;
  color: var(--clr-5);
  font-size: var(--font-size-3);
  background-color: var(--clr-7);
  border-radius: 9999px;
  transition: transform cubic-bezier(0.4, 0, 0.2, 1) 0.3s;
}

.forwards:active {
  transform: translateX(0.5rem);
}

.backwards:active {
  transform: translateX(-0.5rem);
}

.visibilityHidden {
  visibility: hidden;
}
