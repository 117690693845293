@import '../../global-styles/index.scss';

.header {
  position: fixed;
  height: 4.5rem;
  width: 100%;
  padding: 1rem var(--primary-page-gap);
  background-color: var(--clr-3);
  color: var(--clr-2);
  font-family: var(--font-family-4);
  font-size: var(--font-size-1);
  z-index: 9999;
  @include media(medium) {
    & {
      padding: 1rem 2rem;
    }
  }
}

.headerInnerContainer {
  margin: auto;
  max-width: var(--max-width);
  display: flex;
  align-items: center;
}

.logo {
  max-width: 6rem;
}

.nav {
  margin-left: auto;
  display: flex;
}

.navlist {
  display: flex;
  align-items: center;
  gap: 3rem;
  @include media(medium) {
    & {
      gap: 2rem;
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }

  button {
    padding: 0.7rem 1.4rem;
    background-color: var(--clr-1);
    border-radius: 4px;
    color: inherit;
  }
}
