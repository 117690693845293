@import '../../global-styles/index.scss';

.cell {
  display: flex;
  width: 29rem;
  @include media(medium) {
    & {
      width: 100%;
      flex-direction: column;
    }
  }
}

.dataWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include media(medium) {
    & {
      min-height: 18rem;
    }
  }
}

.titleWrapper {
  position: relative;
  width: 100%;
  height: 7.5rem;
  display: flex;
  align-items: center;
  background-color: var(--clr-2);
}

.imageWrapper {
  flex: 1;
}

.title {
  width: 100%;
  position: relative;
  color: white;
  display: inline-block;
  vertical-align: middle;
  padding: 0.6rem;
  font-size: var(--font-size-2);
  font-weight: 600;
  background-color: var(--clr-2);
  text-align: center;
}

.titleWrapper:after {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  content: '';
  font-size: 0;
  line-height: 0;
  margin-left: -1.75rem;
  border-top: 1.25rem solid var(--clr-2);
  border-right: 1.75rem solid transparent;
  border-left: 1.75rem solid transparent;
}

.rarityText {
  font-family: var(--font-family-2);
  color: var(--clr-2);
  font-size: var(--font-size-4);
  font-weight: 600;
}

.rarityText > span {
  font-size: var(--font-size-1);
}

.pieChartWrapper {
  flex: 1;
  height: 20rem;
  display: flex;
  align-items: center;
}

.pieChart {
  width: 7rem;
  aspect-ratio: 1;
  position: relative;
  display: inline-grid;
  margin: 5px;
  place-content: center;
  font-size: 25px;
  font-weight: bold;
  font-family: sans-serif;
  border-radius: 50%;
}

.pieChart:before,
.pieChart:after {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.pieChart:before,
.pieChart:after {
  inset: 0;
}

.pieChart:before {
  margin: 0.325rem 0.325rem;
  width: 6.25rem;
  aspect-ratio: 1;
  background: conic-gradient(var(--clr-2) 100%, #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(98% - 0.25rem),
    #000 calc(100% - 0.25rem)
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(98% - 0.25rem),
    #000 calc(100% - 0.25rem)
  );
}

.pieChart:after {
  background: conic-gradient(var(--clr-1) calc(var(--p) * 1%), #0000 0);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
  mask: radial-gradient(
    farthest-side,
    #0000 calc(99% - var(--b)),
    #000 calc(100% - var(--b))
  );
}
