.cardWrapper {
  min-width: 17rem;
  height: 25rem;
  scroll-snap-align: start;
}

.card {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 2px;
  color: var(--clr-5);
  font-weight: 600;
  border-radius: 7px;
  box-shadow: 0px 7px 5px -3px rgba(0, 0, 0, 0.25);
}

.card:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-top: 35px solid var(--clr-1);
  border-right: 35px solid transparent;
}

.button {
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  font-size: var(--font-size-2);
  background-color: var(--clr-7);
  border-radius: 9999px;
  margin: 0.4rem 1rem;
}

.name {
  z-index: 1;
  color: var(--clr-1);
  width: max-content;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0.2rem 1.7rem;
  font-family: var(--font-family-1);
  font-size: var(--font-size-5);
  margin-bottom: 1rem;
}

.image {
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
}
