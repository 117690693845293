@import '../../global-styles/index.scss';

.section {
  padding: 8rem 0 15rem;
  @include flex-col-center;
  align-items: center;
  background: linear-gradient(
    172deg,
    var(--clr-5) 74%,
    var(--clr-1) 74.2%
  );
}

.title {
  font-family: var(--font-family-1);
  font-size: var(--font-size-6);
  color: white;
  text-transform: uppercase;
}

.cardsWrapper {
  margin-top: 2.2rem;
  display: flex;
  gap: 16px;
}

.walletConnectWrapper {
  margin-top: 4rem;
  @include flex-col-center;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  color: white;
}

.walletConnectText {
  font-weight: inherit;
}

.walletConnectBtn {
  padding: 0.5rem 1.4rem;
  border: solid 2px var(--clr-8);
  border-radius: 28.5px;
  text-transform: uppercase;
  color: var(--clr-8);
}
