@import '../../global-styles/index.scss';

.section {
  display: flex;
  @include media(medium) {
    & {
      flex-direction: column;
      padding-top: 3rem;
    }
  }
}

.faqContainer {
  padding: 9.5rem 3rem 6.25rem;
  width: 60%;
  background-color: var(--clr-2);
  @include media(medium) {
    & {
      padding: 5rem 2rem;
      width: 100%;
    }
  }
}

.title {
  font-family: var(--font-family-1);
  font-size: var(--font-size-6);
  color: white;
}

.faqFilterContainter {
  padding: 16.7rem 0 0;
  width: 40%;
  @include media(medium) {
    & {
      padding: 0;
      width: 100%;
    }
  }
}

.filterButtonsWrapper {
  width: fit-content;
  margin: 0 2rem 0 auto;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  gap: 1rem;
  padding-bottom: 2rem;
  @include media(medium) {
    & {
      margin: 1rem 0;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

.button {
  width: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 1rem;
  font-weight: 600;
  background-color: var(--clr-7);
  color: white;
  border-radius: 9999px;
  border: 0.2rem solid var(--clr-7);
  transition: all 0.15s;
}

.active {
  background-color: white;
  color: var(--clr-7);
}
