@import '../../global-styles/index.scss';

.section {
  padding: var(--primary-page-gap) 0 0;
  @include flex-col-center;
  align-items: center;
  background: linear-gradient(to bottom, var(--clr-1) 60%, transparent 60%),
    linear-gradient(to right, transparent 40%, var(--clr-2) 0);
}

.title {
  font-family: var(--font-family-1);
  font-size: var(--font-size-6);
  color: var(--clr-5);
}

.video {
  margin-top: 2rem;
  width: var(--max-width);
  height: 33.75rem;
  background-color: rgba(#000, 0.6);
}
