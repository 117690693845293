.faqWrapper {
  padding: 1.75rem 0 1.25rem;
  display: flex;
  gap: 0.5rem;
  color: white;
}

.question {
  display: inline-flex;
  align-items: center;
  font-size: var(--font-size-0);
  color: inherit;
  min-height: 2rem;
}

.button {
  color: inherit;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border: 0.15rem solid white;
  height: 2rem;
  width: 2rem;
  font-size: var(--font-size-0);
  border-radius: 9999px;
}

.answer {
  font-weight: 200;
  font-size: 0.875rem;
  color: inherit;
  overflow-y: hidden;
  transition: max-height 0.25s ease-in-out;
}
