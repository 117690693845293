.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}

.desktopOnly {
  @include media(medium) {
    & {
      display: none !important;
    }
  }
}

.mobileOnly {
  @include media(medium, min) {
    & {
      display: none !important;
    }
  }
}

.srOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  clip-path: inset(50%);
  white-space: nowrap;
  border: 0;
}
