@import '../../global-styles/index.scss';

.video {
  width: 100%;
  padding-top: 5rem;
  display: none;
  @include media(medium, min) {
    & {
      display: block;
      padding-top: 0;
    }
  }
}
