@import '../../global-styles/index.scss';

$ticketWidth: 24rem;

$maskImageColorValue: (
  black 2px,
  black 5%,
  black 10%,
  black 15%,
  black 20%,
  black 25%,
  black 30%,
  black 35%,
  black 40%,
  black 45%
);

.section {
  padding: 11.25rem var(--primary-page-gap) var(--primary-page-gap);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: stretch;
  gap: 1.5rem;
  background-image: url(../../../public/assets/goldenticket-bg.png);
  background-size: cover;
  @include media(medium) {
    & {
      padding: var(--primary-page-gap) 2rem;
    }
  }
}

.ticket {
  position: relative;
  padding: 2.5rem 0;
  max-width: $ticketWidth;
  width: 100%;
  border-radius: 2px;
  background-color: white;
  color: var(--clr-5);
  font-family: var(--font-family-1);
  font-size: var(--font-size-6);
  text-align: center;
  line-height: 1;
  mask-image: radial-gradient(
      circle at left center,
      transparent 20px,
      $maskImageColorValue,
      black 70%,
      transparent 0
    ),
    radial-gradient(
      circle at right center,
      transparent 20px,
      $maskImageColorValue,
      transparent 0
    );
}

.text {
  margin: auto;
  max-width: $ticketWidth + 10rem;
  color: white;
  font-weight: 600;
  font-size: var(--font-size-3);
  text-align: center;
  line-height: 1.2;
}
