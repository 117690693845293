@import '../../global-styles/index.scss';

$maxWidth: calc(var(--max-width) / 2.3);

.section {
  display: flex;
  background-color: white;
  @include media(medium) {
    & {
      flex-direction: column;
    }
  }
}

.logoContainer {
  flex: 1;
  display: grid;
  place-content: center;
  @include media(medium) {
    & {
      padding: 6rem 0;
    }
  }
}

.logo {
  max-width: 20rem;
  margin: 0 auto;
}

.aboutContainer {
  flex: 1;
  padding: 7rem var(--primary-page-gap) 3rem 4rem;
  background-color: var(--clr-2);
  color: white;
  @include media(medium) {
    & {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.aboutTitle {
  max-width: $maxWidth;
  font-family: var(--font-family-1);
  font-size: var(--font-size-7);
  line-height: 1;
}

.aboutText {
  margin: 1rem 0 6rem;
  max-width: $maxWidth;
  font-weight: 600;
  line-height: 1.2;
  font-size: var(--font-size-1);
}

.launchState {
  font-family: var(--font-family-1);
  font-size: var(--font-size-8);
}
